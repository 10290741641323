<template>
  <div id="editAvailability">
   
    <div class="items-center justify-center">
      <b-row>
        <b-col cols="12" md="4" >
        <b-card class="">

        <p style="font-weight: bold; color: #454a62">
              {{ $t("Online Consultation Latest Price") }}
            </p>

            <b-row >
              <b-col cols="12" md="6"> <vs-input type="number" v-model="priceModel.Price"  /></b-col>
              <b-col cols="12" md="6"> <v-select
                label="Name"
                v-model="priceModel.CurrencyID"
                :options="currencies"
                :placeholder="$t('Currency')"
                :reduce="(obj) => obj.ID"
              /></b-col>

             
              <!-- <p class="mt-2" style="font-size:30px">$</p> -->
             
       </b-row>

          </b-card>
          <b-card class="">
            <div class="">
              <b-row >
                <b-col cols="12" md="4">
                  <p
                  style="font-weight: bold; color: #454a62"
                >
                  {{ $t("StartDate") }}
                </p>
                </b-col>
                <b-col cols="12" md="6">
                <datepicker
                  :placeholder="$t('StartDate')"
                  v-model="model.DateFrom"
                  name="StartDate"
                  :format="'dd MMM'"
                ></datepicker>
              </b-col>
              </b-row>
              <b-row >
                <b-col cols="12" md="4">
                  <p
                  style="font-weight: bold; color: #454a62"
                >
                  {{ $t("EndDate") }}
                </p>
                </b-col>
                <b-col cols="12" md="6">
                  <datepicker
                  :placeholder="$t('EndDate')"
                  v-model="model.DateTo"
                  name="EndDate"
                  :maximumView="'month'"
                  :format="'dd MMM'"
                ></datepicker>
                </b-col>
               
               
              </b-row>

              <b-row >
                <b-col cols="12" md="4" >
                  <p style="font-weight: bold; color: #454a62" >
                    {{ $t("ChooseYear") }}
                  </p>
                </b-col>
                <b-col cols="12" md="6" > <datepicker
                    :minimumView="'year'"
                    v-model="model.Year"
                    :format="yearFormat"
                  ></datepicker></b-col>
               
              </b-row>
            </div>
          </b-card>
          <b-card>
            <div>
              <p style="font-weight: bold; color: #454a62">
                {{ $t("ChooseDays") }}
              </p>
            </div>
            <!-- days -->
            <b-row>
              <b-button
                size="small"
                @click="model.Sunday = !model.Sunday"
                class="m-1 "
                :variant="model.Sunday ? 'primary' : 'white'"
                :style="[model.Sunday ? {} : { border: ' 2px solid #1F1F1F' }]"
                style="border-radius: 34px; opacity: 1; border-color: black"
                ><span
                  :style="[
                    model.Sunday ? { color: 'white' } : { color: 'black' },
                  ]"
                >
                  {{ $t("Sun") }}</span
                >
              </b-button>
              <b-button
                size="small"
                @click="model.Monday = !model.Monday"
                :variant="model.Monday ? 'primary' : 'white'"
                :style="[model.Monday ? {} : { border: ' 2px solid #1F1F1F' }]"
                style="border-radius: 34px; opacity: 1"
                class="mt-1 mb-1"
                ><span
                  :style="[
                    model.Monday ? { color: 'white' } : { color: 'black' },
                  ]"
                >
                  {{ $t("Mon") }}</span
                >
              </b-button>
              <b-button
                size="small"
                @click="model.Tuesday = !model.Tuesday"
                :variant="model.Tuesday ? 'primary' : 'white'"
                :style="[model.Tuesday ? {} : { border: ' 2px solid #1F1F1F' }]"
                style="border-radius: 34px; opacity: 1"
                class="m-1"
                ><span
                  :style="[
                    model.Tuesday ? { color: 'white' } : { color: 'black' },
                  ]"
                >
                  {{ $t("Tues") }}</span
                >
              </b-button>
              <b-button
                size="small"
                @click="model.Wednesday = !model.Wednesday"
                :variant="model.Wednesday ? 'primary' : 'white'"
                :style="[
                  model.Wednesday ? {} : { border: ' 2px solid #1F1F1F' },
                ]"
                style="border-radius: 34px; opacity: 1"
                class="mt-1 mb-1"
                ><span
                  :style="[
                    model.Wednesday ? { color: 'white' } : { color: 'black' },
                  ]"
                >
                  {{ $t("Wednes") }}</span
                >
              </b-button>
              <b-button
                size="small"
                @click="model.Thursday = !model.Thursday"
                :variant="model.Thursday ? 'primary' : 'white'"
                :style="[
                  model.Thursday ? {} : { border: ' 2px solid #1F1F1F' },
                ]"
                style="border-radius: 34px; opacity: 1"
                class="m-1"
                ><span
                  :style="[
                    model.Thursday ? { color: 'white' } : { color: 'black' },
                  ]"
                >
                  {{ $t("Thurs") }}</span
                >
              </b-button>
              <b-button
                size="small"
                @click="model.Friday = !model.Friday"
                :variant="model.Friday ? 'primary' : 'white'"
                :style="[model.Friday ? {} : { border: ' 2px solid #1F1F1F' }]"
                style="border-radius: 34px; opacity: 1"
                class="mt-1 mb-1"
                ><span
                  :style="[
                    model.Friday ? { color: 'white' } : { color: 'black' },
                  ]"
                >
                  {{ $t("Fri") }}</span
                >
              </b-button>
              <b-button
                size="small"
                @click="model.Saturday = !model.Saturday"
                :variant="model.Saturday ? 'primary' : 'white'"
                :style="[
                  model.Saturday ? {} : { border: ' 2px solid #1F1F1F' },
                ]"
                style="border-radius: 34px; opacity: 1"
                class="m-1"
                ><span
                  :style="[
                    model.Saturday ? { color: 'white' } : { color: 'black' },
                  ]"
                >
                  {{ $t("Sat") }}</span
                >
              </b-button>
            </b-row>
          </b-card>

          <b-card >
            <b-row class="m-1">
              <b-col cols="12" md="4">
                <p
                style="font-weight: bold; color: #454a62"
              >
                {{ $t("From") }}
              </p>
              </b-col>
              <b-col cols="12" md="6">
              <vs-input type="time" v-model="model.TimeFrom"></vs-input>

              </b-col>
            </b-row>
            <b-row class="m-1" >
              <b-col cols="12" md="4">
                <p
                style="font-weight: bold; color: #454a62"
              >
                {{ $t("To") }}
              </p>
              </b-col>
              <b-col cols="12" md="6">
              <vs-input type="time" v-model="model.TimeTo"></vs-input>

              </b-col>
            </b-row>
           
          </b-card>

          <b-row class="pl-2 pr-2">
            <b-button
              style="border-radius: 34px; opacity: 2; height: 35px"
              @click="ConfirmEditAvailability=true"
              class=""
              variant="primary"
              :disabled="!validEdit"
              >{{ $t("EditAvailability") }}</b-button
            >

            <b-button
              style="border-radius: 34px; opacity: 2; height: 35px"
              variant="danger"
              @click="Block"
              :disabled="!validBlock"
              class="ml-1 mr-1"
              >{{ $t("Block") }}</b-button
            >
          </b-row>
        </b-col>
<b-col cols="12" md="8">
  <b-card >
          <!-- <div class="vx-row">
                
              </div> -->
          <p
            class="mr-6"
            style="font-weight: bold; color: #454a62"
            v-if="DoctorSessions != null && DoctorSessions.length > 0"
          >
            {{ $t("DoctorPrice") }} 
            <label style="font-weight: normal">
              {{
                DoctorSessions[midOfDoctorSessionList].Price
              }}
              {{DoctorSessions[midOfDoctorSessionList].Currency.NameEN  }}
            </label>
          </p>
          <vs-button
            style="border-radius: 34px; opacity: 2; height: 35px"
            color="#76A224"
            class="m-1"
            @click="showYearSummary"
            >{{ $t("ShowYearSummary") }}</vs-button
          >
          <FullCalendar
            defaultView="dayGridMonth"
            ref="fullCalendar"
            selectable="true"
            :disabled="true"
            :options="configOptions"
            editable="true"
            :height="1000"
            :width="800"
          />
        </b-card>
</b-col>
        
</b-row>

      <vs-popup v-if="ShowBlockPopup" title="" :active.sync="ShowBlockPopup">
        <div class="vx-col mycard justify-center items-center">
          <b-card>
            <div slot="no-body">
              <div class="no-gutter justify-center items-center MyBG">
                <div class="align-center p-10">
                  <img
                    src="@/assets/images/logo.png"
                    style="max-width: 150px"
                    alt="login"
                    class="mx-auto mt-3"
                  />
                  <div class="mt-10">
                    <p
                      style="
                        text-align: initial;
                        font: normal normal 200 20px/21px Futura PT;
                        letter-spacing: 0px;
                        color: #ffffff;
                      "
                    >
                      {{ $t("DoctorBlockAvailabilty1") }}
                    </p>
                    <p
                      style="
                        text-align: initial;
                        font: normal normal 200 20px/21px Futura PT;
                        letter-spacing: 0px;
                        color: #ffffff;
                      "
                    > 
                      {{ $t("DoctorBlockAvailabilty2") }}
                    </p>
                    <p style="
                        text-align: initial;
                        font: normal normal 200 20px/21px Futura PT;
                        letter-spacing: 0px;
                        color: #ffffff;
                      "
                      v-html="BlockMessage"></p>
                  </div>

                  <div
                    class="
                      flex flex-wrap
                      justify-center
                      flex-col-reverse
                      sm:flex-row
                    "
                  ></div>
                </div>
              </div>
            </div>
          </b-card>
        </div>
      </vs-popup>



       <vs-popup v-if="ConfirmEditAvailability" title="" :active.sync="ConfirmEditAvailability">
        <b-col class=" justify-center items-center">
            <div class="align-center p-10" style="text-align: center;">
                  <img
                    src="@/assets/images/logo.png"
                    style="max-width: 150px;text-align: center; "
                    alt="login"
                    class="mx-auto mt-1"
                  />
                  <div class="mt-2">
                    <p
                      style="
                        text-align: center;
                        font: normal normal 200 20px/21px Futura PT;
                        letter-spacing: 0px;
                      "
                    >
                      {{ $t("ConfirmEditAvailability") }}
                    </p>
                   
                    
                  </div>

                  <div
                    class="
                      flex flex-wrap
                      justify-center
                      flex-col-reverse
                      sm:flex-row
                    "
                  >
                     <div>
                    <div class="flex flex-wrap items-center p-6" slot="footer">
      <b-button  class="m-1" @click="EditAvailability(true)"  variant="primary" >{{
        $t("Yes")
      }}</b-button>
      <b-button
      class="m-1"
        type="border"
        variant="danger"
        @click="EditAvailability(false)"
        >{{ $t("No") }}</b-button
      >
    </div>
                  </div></div>

               
                </div>
        </b-col>
      </vs-popup>
    </div>
    <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <b-button type="border" color="danger" @click="$router.go(-1)">{{
              $t("Back")
            }}</b-button>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import { domain } from "@/gloabelConstant.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import moduleCurrency from "@/store/settings/currency/moduleCurrency.js";
import "@fullcalendar/core/vdom"; // solve problem with Vite
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import FullCalendar from "@fullcalendar/vue";
import { BAlert, BButton, BCard, BCardBody, BCardText, BCol, BFormTextarea, BLink, BRow, BTableLite } from 'bootstrap-vue';
import Datepicker from "vuejs-datepicker";

export default {
  data() {
    return {
      ConfirmEditAvailability:false,
      priceModel: {},
      ShowBlockPopup: false,
      baseURL: domain,
      showSummeryYear: true,
      calendarStart: null,
      sessionDataYear: [],
      calendarPlugins: [
        dayGridPlugin,
        interactionPlugin,
        // listPlugin,
        timeGridPlugin,
      ],
      BlockMessage: "",
      DoctorSessions: [],
      midOfDoctorSessionList: 0,
      SearchModel: {
        DoctorID: this.$store.state.AppActiveUser.Doctor.ID,
        SetPrice: true,
      },
      model: {
        Sunday: false,
        Saturday: false,
        Friday: false,
        Thursday: false,
        Wednesday: false,
        Tuesday: false,
        Monday: false,
        Months: [],
      },
      yearFormat: "yyyy",
    };
  },
  components: {
    FullCalendar,
    dayGridPlugin,
    interactionPlugin,
    timeGridPlugin,
    Datepicker,
    BAlert, BButton, BCard, BCardBody, BCardText, BCol, BFormTextarea, BLink, BRow, BTableLite
  },
  computed: {
     currencies() {
      return this.$store.state.CurrencyList.currencies;
    },
    validEdit() {
      debugger;
      return (
        this.model.TimeFrom != undefined &&
        this.model.TimeTo != undefined &&
        this.model.Year != undefined &&
        this.model.DateFrom != undefined &&
        this.model.DateTo != undefined &&
        (this.model.Sunday ||
          this.model.Monday ||
          this.model.Tuesday ||
          this.model.Wednesday ||
          this.model.Thursday ||
          this.model.Friday ||
          this.model.Saturday)
      );
    },
    validBlock() {
      debugger;
      return (
      
        this.model.Year != undefined &&
        this.model.DateFrom != undefined &&
        this.model.DateTo != undefined
      );
    },
    configOptions() {
      return {
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        events: this.DoctorSessions,
        datesSet: this.handleMonthChange,
        weekends: this.weekendsVisible,
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        },
        initialView: "dayGridMonth",
      };
    },
  },
  methods: {
    showYearSummary() {
      window.open("DoctorYearSummaryAppointments", "_blank");
    },

    formatJsDate(dateToFormat) {
      debugger;
      return (
        dateToFormat.getFullYear() +
        "-" +
        (dateToFormat.getMonth() + 1) +
        "-" +
        dateToFormat.getDate()
      );
    },
    handleMonthChange(arg) {
      if (this.calendarStart != arg.startStr) {
        this.calendarStart = arg.startStr;
        this.SearchModel.DateFrom = this.formatJsDate(arg.view.activeStart);
        this.SearchModel.DateTo = this.formatJsDate(arg.view.activeEnd);
        this.SimpleSearchDoctorSessions();
      }
    },

    back() {
      this.$router.go(-1);
    },
    clearData()
    {
      this.ConfirmEditAvailability = false;
          this.model= {
                Sunday: false,
                Saturday: false,
                Friday: false,
                Thursday: false,
                Wednesday: false,
                Tuesday: false,
                Monday: false,
                Months: [],
            }
    },

    EditAvailability(keepInitial) {
     // this.SaveDoctorSessionPrice();
      this.$vs.loading();
      this.model.Price = this.priceModel.Price;
      this.model.CurrencyID = this.priceModel.CurrencyID;
      this.model.DoctorID = this.$store.state.AppActiveUser.Doctor.ID;
      this.model.KeepInitial = keepInitial;

      this.$store
        .dispatch("DoctorList/EditAvailability", this.model)
        .then((res) => {
          if (res.status == 200) {
            this.SimpleSearchDoctorSessions();
            window.showSuccess(res.data.message);
            this.clearData();
          }
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          window.showError(err.response.data.message);
        });
    },
       getSesionPrice() {
      this.$vs.loading();

      this.$store
        .dispatch(
          "DoctorList/GetDoctorSessionPriceByDoctorID",
          this.$store.state.AppActiveUser.Doctor.ID
        )
        .then((res) => {
          if (res.status == 200) {
            this.priceModel = res.data.Data;
            this.$vs.loading.close();
          }
        })
        .catch(() => {
          this.$vs.loading.close();

          window.showError();
        });
    },
    Block() {
      //  this.ShowBlockPopup = true;
      this.$vs.loading();
      this.model.DoctorID = this.$store.state.AppActiveUser.Doctor.ID;
      this.$store
        .dispatch("DoctorList/BlockAvailability", this.model)
        .then((res) => {
          debugger;
          if (res.status == 200) {
            debugger;
            this.BlockMessage = res.data.Message;
            if (res.data.Data.length > 0) this.ShowBlockPopup = true;
            else {
              this.$vs.notify({
                title: "successfully",
                text: res.data.Message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success",
              });
            }
           this.clearData();
            this.SimpleSearchDoctorSessions();
          }
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          window.showError(err.response.data.message);
        });
    },
     SaveDoctorSessionPrice() {
      this.$store
        .dispatch("DoctorList/SaveDoctorSessionPrice", this.priceModel)
        .then((res) => {
          if (res.status == 200) {
            this.$vs.loading.close();
          }
        })
        .catch(() => {
          this.$vs.loading.close();

          window.showError();
        });
    },
 SimpleSearchDoctorSessions() {
      this.DoctorSessions = [];

      this.$vs.loading();
      this.$store
        .dispatch("DoctorList/SimpleSearchDoctorSessions", this.SearchModel)
        .then((res) => {
          debugger
          this.DoctorSessions = res.data.Data;
           this.midOfDoctorSessionList = this.DoctorSessions.length;
           if(this.DoctorSessions.length==1){
             this.midOfDoctorSessionList = 0;
           }
          else if (this.DoctorSessions.length % 2 == 0) {
            this.midOfDoctorSessionList = this.midOfDoctorSessionList / 2;
          } else {
            this.midOfDoctorSessionList = (this.midOfDoctorSessionList + 1) / 2;
          }
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
  
  },
  created() {
    if (!moduleCurrency.isRegistered) {
      this.$store.registerModule("CurrencyList", moduleCurrency);
      moduleCurrency.isRegistered = true;
    }

    this.$store.dispatch("CurrencyList/GetAllCurrencies");
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }

    var modelSearch = {};
    modelSearch.DoctorID = this.$store.state.AppActiveUser.Doctor.ID;
    this.getSesionPrice();
   
  },
  mounted() {
    var vm = this;
        vm.$refs.fullCalendar.getApi().next();
    setTimeout(function () {
      //if(vm.DoctorSessions==null||vm.DoctorSessions.length==0)
      {
    
        vm.$refs.fullCalendar.getApi().prev();
      }
    }, 500);
  },
};
</script>
<style >
.con-vs-popup .vs-popup {
  box-shadow: unset;
}
</style>
<style scoped>
.MyBG {
  background: transparent
    radial-gradient(closest-side at 50% 50%, #2f5f80 0%, #003267 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: -3px 0px 22px #00000029;
  border-radius: 31px 31px 31px 31px;
  opacity: 1;
}
.vx-card {
  background-color: transparent !important;
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.vs-popup--content {
  box-shadow: unset;
}
</style>
<style>
</style>
